import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { jsx, Flex } from "theme-ui"
import { useColorMode } from 'theme-ui'
import { Styled, Grid,Box, NavLink} from 'theme-ui'
import './switch-slider.css'
import logo from '../images/KennyDN.png'
import search from '../images/search.png'
import Img from "gatsby-image"
import Search from "../pages/search"
import './header.css'; // Import regular stylesheet

function Header({ siteTitle, menuLinks}) {
  const [colorMode, setColorMode] = useColorMode()
  return(
  <Flex className="header-menu"> 
      <Grid as='nav' gap={1} columns={[7, null, 7]}  sx={{gridAutoFlow: 'row'}}>
      {menuLinks.map(link => (
        <Box key={link.name} className="menu-box" >
            <Box className="circle-box"></Box>
            <NavLink href={link.Link}>
            <br/>
            {link.name}
            </NavLink >
        </Box>
        )
      )}
        </Grid>
      </Flex>
    )
    }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
